import React, { useRef , useState}  from 'react';

function Ref(){
    
    const refElement = useRef("");
    //console.log(refElement)
    const [name,setName] = useState("udit");
    
    function Ref(){
        setName("")
    }
   
   return(
       <>
       <p> ref example </p>
       <input type="text" value= {name } onChange= {(e) => setName(e.target.value)}  />
       <button onClick ={Ref} >Reset </button>
       </>
       );
   
    
    
}

export default Ref;