import React from "react";
import Blog from "./Blog";


function Contact() {
    return (

<>
<Blog />
<br></br><br></br>
<h5>Contact page</h5>   
  </>  
    );
}

export default Contact;