import React,{ useState, useEffect } from 'react';
import './Register.css';


function Register() {
    
    const data = {name:"" , city:"" , state:""}
    const [inputData , setInputData] = useState(data);
    const [flag , setFlag ] = useState(false)
    
    useEffect(() => {
        console.log("Registered")
    },[flag])
    
    function handelData(e){
        setInputData({...inputData, [e.target.name]: e.target.value })
        console.log(inputData);
    }
    
    function handleSubmit(e) {
        e.preventDefault();
        if(!inputData.name || !inputData.city || !inputData.state) {
            alert ("all inputs fiels are required");
        }
        
        else {
            setFlag(true)
            //alert ("register sucessfull");
        }
        
    }
    
    return(
        <>
         <pre> {(flag)? <h4> hello {inputData.name} you are registered successfully </h4>:""}</pre>
         <div class ="container">
        <form  className="form" onSubmit ={handleSubmit}>
        <h5 class="heading"> Register Form </h5>
        
        <div class = "input" >

       <input type ="text" class="text" name= "name"  value ={inputData.name}  onChange={handelData}   placeholder ="your name" /><br></br><br></br>
        <input type ="teext" class="text"   name="city" value ={inputData.city}   onChange={handelData}    placeholder ="your city" /> <br></br><br></br>
        <input type ="teext" class="text"  name="state" value ={inputData.state}  onChange={handelData}   placeholder ="your state" /> <br></br><br></br>

        <button type ="submit" class="click" style={{color:'green'}} >Submit </button>
        </div>
     
        
        </form>
        </div>
        </>
        )
    
}

export default Register;