import React, { useState } from 'react';
import './Quiz.css';

function Quiz() {
  const questions = [
    {
      question: 'What is the capital of France?',
      answers: [
        { text: 'Paris', correct: true },
        { text: 'Berlin', correct: false },
        { text: 'Madrid', correct: false },
        { text: 'Rome', correct: false }
      ]
    },
    {
      question: 'Who painted the Mona Lisa?',
      answers: [
        { text: 'Pablo Picasso', correct: false },
        { text: 'Vincent van Gogh', correct: false },
        { text: 'Leonardo da Vinci', correct: true },
        { text: 'Claude Monet', correct: false }
      ]
    },
    {
      question: 'What is the largest planet in our solar system?',
      answers: [
        { text: 'Jupiter', correct: true },
        { text: 'Saturn', correct: false },
        { text: 'Neptune', correct: false },
        { text: 'Uranus', correct: false }
      ]
    },
    {
      question: 'Who invented the telephone?',
      answers: [
        { text: 'Alexander Graham Bell', correct: true },
        { text: 'Thomas Edison', correct: false },
        { text: 'Nikola Tesla', correct: false },
        { text: 'Marie Curie', correct: false }
      ]
    },
    {
      question: 'delhi is in?',
      answers: [
        { text: 'india', correct: true },
        { text: 'americs', correct: false },
        { text: 'telangana', correct: false },
        { text: 'russia', correct: false }
      ]
    }
    
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);

  const handleAnswerOptionClick = (isCorrect) => {
    if (isCorrect) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };

  return (
    <div className='app'>
      {showScore ? (
        <div className='score-section'>
          You scored {score} out of {questions.length}
        </div>
      ) : (
        <>
          <div className='question-section'>
            <div className='question-count'>
              <span>Question {currentQuestion + 1}</span>/{questions.length}
            </div>
            <div className='question-text'>{questions[currentQuestion].question}</div>
          </div>
          <div className='answer-section'>
            {questions[currentQuestion].answers.map((answerOption) => (
              <button className="click" onClick={() => handleAnswerOptionClick(answerOption.correct)}>
                {answerOption.text}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Quiz;
