import React, { useState, useEffect } from 'react';

function MyComponent() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('https://testing.bwjts.com/udhit/api.php')
      .then(response => response.json())
      .then(data => setData(data));
  }, []);

  return (
    <div>
      <h6>api chatgpt</h6>
      <ul>
        {data.map(item => (
          <li key={item.id}><br></br>{item.name}</li>
          
        ))}
      </ul> 
    </div>
  );
}

export default MyComponent;