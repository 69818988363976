

function City() {
const name = <h6> aua</h6>;
const city = <h6> hyd</h6>;
const state = <h6> ts</h6>;
    const Users = (
        <>
       
        {name}
        {city}
        {state}
    
        </>
      );

return (
    <div class name ="City">
        <h6  style={{color: "red"}}>{Users}</h6>
        <h6 style={{color: "orange"}}>{Users}</h6>
        <h6 style={{color: "blue"}}>{Users}</h6>
    </div>
);


}

export default City;
