import React from 'react';

function Map() {
    
    const array1 =["hi","hello" ,"bye" , "welcome"]
    const output = array1.map((array1)=><h1>{array1}</h1>)
    return(
         
         <>
         <p>  {output} </p>
         </>
        
        );
    
}

export default Map;