import axios from 'axios';
import React, { useState, useEffect } from 'react';
//import axios from 'axios';

function Phpapi() {

  const [userData, setUserData] = useState([]);
useEffect( () => {
  const getUserData = async() => {
    const reqData =await fetch("http://localhost/api/api.php")
    const resData = await reqData.json();
    console.log(resData);
    setUserData(resData);

  }
  getUserData();
  
},[]);

return (
  <>
  <h6>api</h6>
  </>
);






// function Phpapi() {
//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const[user ,  setUser] = useState([]);

// const loadUsers = async () => {

// const result = await axios.get("http://localhost/api/api.php");
// setUser(result.data.response);
// console.log(result.data.result);

// };

// useEffect(() => {
//   loadUsers();
// },[]);


  // useEffect(() => {
  //   fetch('http://localhost/api/api.php')
  //     .then(response => response.json())
  //     .then(data => setUsers(data));
  // }, []);

  // return (
  //   <>
  //   <h5>php api</h5>
  //   <ul>
  //     {users.map(user => (
  //       <li key={user.id}>
  //         {user.name} ({user.email})
  //       </li>
  //     ))}
  //   </ul>
  //   </>
  // );
}

export default Phpapi;