import React from 'react';
import './Nav.css';
import { BrowserRouter as  Link } from 'react-router-dom';

function Menu() {
    return (

     <>

      <ul>
        <li>
          <Link to="/Colors">Home</Link>
        </li>
        <li>
          <Link to="/City">City</Link>
        </li>
        <li>
          <Link to="/Form">Form</Link>
        </li>
      </ul>
      </>
      
    );
  }
   

export default Menu;






