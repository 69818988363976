import React from 'react';

function Conditional(){
    let a=20;
    let b=10;
    console.log(a , b );

const Play = () => {
alert ('well played');
}

const Click = () =>{
    console.warn('console message');
}

   
    return(
        <>
         <button type="button"   onClick={Play} style={{color:'red'}} >click</button><br></br>
         <button type="button" onClick={Click} >click</button>
        </>
    );
}

export default Conditional;