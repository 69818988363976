
import React, { useState } from 'react';

function Update() {
  const [data, setData] = useState({
    id: '',
    name: ''
    
  });

  const handleInputChange = event => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    fetch("https://testing.bwjts.com/udhit/update.php/${data.id}", {
      method: 'POST',
      mode: 'no-cors', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error(error));
  }

  return (
      <>
      <p>Update userdata </p>
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          ID:
          <input type="text" name="id" value={data.id} onChange={handleInputChange} />
        </label>
      </div>
      <div>
        <label>
          Name:
          <input type="text" name="name" value={data.name} onChange={handleInputChange} />
        </label>
      </div>
      <div>
      
      </div>
      <button type="submit">Update Data</button>
    </form>
  </>
  );
}

export default Update;











// import React ,{useState} from 'react';

// import axios from 'axios';

// function Update() {

// const [data, setData] = useState({
//     id:'',
//     name:''
// })

// // events ----

// const handleInput = (event) =>{
//     setData({...data,[event.target.name]: event.target.value });
// };


// function handleSubmit(event){
//     event.preventDefault()
//     //console.log(post);
//     const postData = put;
// fetch('https://testing.bwjts.com/udhit/update/${data,id}', {
//   method: 'PUT',
//   crossorigin: true,    
//     mode: 'no-cors', 
//   headers: {
//     'Content-Type': 'application/json'
//   },
//   body: JSON.stringify(data)
// })
//   .then(response => response.json())
//   .then(data => console.log(data))
//   .catch(error => console.error("error getting:".error));
// }
  
//         return (
//             <>
//             <div className="wrapper">
//                 <form onSubmit={handleSubmit}>
//                  <div className="form-group">
//                         <label>User Id</label>
//                         <input type="text" name="id" onChange={handleInput}  value={data.id} className="form-control" />
//                     </div>
//                     <div className="form-group">
//                         <label>Add User Name</label>
//                         <input type="text" name="name" onChange={handleInput} value={data.name} className="form-control" />
//                     </div>
//                     <div className="form-group">
//                         <input type="submit" value="Create User" className="btn btn-success btn-block" />
//                     </div>
//                 </form>
//             </div>
//             </>
//         );
    
// }

// export default Update;

