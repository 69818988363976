import logo from './logo.svg';
import './App.css';
import Alert from './Alert';
import Form from './Form';
import City from './City';
import Menu from './Menu';
import CityName from "./Colors";
import Contact from "./Contact";
import Blog from "./Blog";
import Error from "./Error";
import ContactForm from "./Form";




import { BrowserRouter , Route,Routes, Link } from 'react-router-dom';

<title>Sample form</title>


function App() {
  return (

   
  <BrowserRouter>
<Routes>
  <Route path="/contact" element ={<Contact />} />
  <Route path="/blog" element ={<Blog />} />
  <Route path="/form" element ={<ContactForm />} />
  <Route path="*" element ={<Error/>} />
</Routes>
  </BrowserRouter>


  ); 
}


export default App;


