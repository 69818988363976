//   working delete code with post method ------------------------
import React ,{useState} from 'react';
import axios from 'axios';

function Delete() {

const [post, setPost] = useState({
    id:'',
})
const handleInput = (event) =>{
    setPost({[event.target.name]: event.target.value })
}

function handleSubmit(event){
    event.preventDefault()
    //console.log(post);
    const postData = post;
fetch('https://testing.bwjts.com/udhit/delete.php', {
  method: 'POST',
  crossorigin: true,    
    mode: 'no-cors', 
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(postData)
})
  .then(response => response.json())
  .then(data => console.log(data))
  .catch(error => console.error("error getting:".error));
}
  
        return (
            <>
            <p>delete api </p>
            <div className="wrapper">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>enter Userid To Delete</label>
                        <input type="text" name="id" onChange={handleInput} className="form-control" />
                    </div>
                    <div className="form-group">
                        <input type="submit" value="Create User" className="btn btn-success btn-block" />
                    </div>
                </form>
            </div>
            </>
        );
    
}

export default Delete;








// //delete code with another way  , not workeing -------

// import React, { useState } from 'react';

// function Delete() {
//   const [id, setId] = useState({
//           id: '',
//   });


//   const handleInputChange = (event) =>{
//     setId({[event.target.name]: event.target.value })
// }

  
//   const handleDelete = event => {
//     event.preventDefault();
    
//         const postData = post;

//     fetch('https://testing.bwjts.com/udhit/delete.php', {
//       method: 'POST',
//         crossorigin: true,    
//       mode: 'no-cors', 
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(postData)
//     })
//       .then(response => response.json())
//       .then(data => console.log(data))
//       .catch(error => console.error(error));
//   }

//   return (
//       <>
//     <form onSubmit={handleDelete}>
//       <div>
//         <label>
//           ID:
//           <input type="text" name="id" onChange={handleInputChange} />
//         </label>
//       </div>
//       <button type="submit">Delete Data</button>
//     </form>
//     </>
//   );
// }

// export default Delete;








// another delete api code --------

// import React , { useState } from 'react';

// function Delete(){
    
//      const [data, setData] = useState({
//     id: ''
    
//   });

//   const handleInputChange = event => {
//     setData({ ...data, [event.target.name]: event.target.value });
//   };

//   const handleSubmit = event => {
//     event.preventDefault();
//     fetch("https://testing.bwjts.com/udhit/delete.php/${data.id}", {
//       method: 'GET',
//       mode: 'no-cors', 
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(data)
//     })
//       .then(response => response.json())
//       .then(data => console.log(data))
//       .catch(error => console.error(error));
//   }

//   return (
//       <>
//       <h6> Delete api </h6>
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label>
//           ID:
//           <input type="text" name="id" value={data.id} onChange={handleInputChange} />
//         </label>
//       </div>
     
//       <button type="submit">Delete Data</button>
//     </form>
//     </>
//   );
// }

// export default Delete;
