import logo from './logo.svg';


function New(props) {
  //console.log(props)

  let Array1 = [1,2,3,4];
  let Array2 = [5,6,7];
  let Array3 =  [...Array1,...Array2];
  console.log(Array3);

  return (
    <>
    <h1 style={{color: "red"}}> hello i am in hyderabad.... </h1>
    <p style={{color: "blue"}}>helllo iwqued qudh iu3 ui3dehiu23 3uiheiu3 jfqwiuhfq wuefhw ufi wiufhwifn iwhfwoiuefh ergienveuri iuwefhiwqfn </p>
    <ul style={{color: "orange"}}>
        <li>gurugram</li>
        <li>hyderabad</li>
        <li>banglore</li>
        <li>pune</li>
        <li>noida</li>

    
    </ul>

    <h5>hello {props.name}</h5>

    </>
  );
}

export default New;
