import React from "react";
import { NavLink } from "react-router-dom";

function Blog() {
    return  (
      <>
     <header>
        
        
        <nav class="Navigation col-lg-12 "  >
        <div class ="row" style={{backgroundColor:"lightblue"}}>
        <p>Navigation Menus</p>
            <ul>
                <li style={{display:"inline" }} >
                    <b><NavLink  class ="active" to ="/contact" style={{color:"red"}} >Contact</NavLink></b>
                    </li>
                    
                    <li style={{display:"inline" , marginLeft:"30px"}}>
                    <b><NavLink to ="/blog" style={{color:"red"}}>Blog</NavLink></b>
                    </li>

                    <li style={{display:"inline",marginLeft:"30px" }}>
                    <b><NavLink to ="/form" style={{color:"red"}} >Form</NavLink></b>
                    </li>
            </ul>
            </div>
        </nav>
     </header>

      </>
    )

};

export default Blog;
