import {data,data1} from './Usecontext' ;
import React , { useState,useEffect,useContext } from 'react';


function Childcontext(){
    const name = useContext(data);
    const city = useContext(data1);

    return(
<>
<h5> hello i am  {name} and i am in {city} now .</h5>
</>
    );
}

export default Childcontext;