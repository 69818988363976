import Childcontext from './Childcontext';
import React , { createContext } from 'react';

// imp  create,provider,usecontext


const data = createContext();
const data1 = createContext();


function Usecontext(){
    const Name = "udit"
    const City ="hyderabad"
    return(
        <>
        <data.Provider value={Name}>
        <data1.Provider value={City}>
        <Childcontext />
        </data1.Provider >
        </data.Provider >
        </>
    );
}

export default Usecontext;
export {data,data1}