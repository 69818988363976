import React, { useContext }  from 'react';
import {data , data2 } from './Parentcomponent';

function Childcomponent() {
    const name = useContext(data);
    const course = useContext(data2); 
    
 // by useing context hook ---------
 
    return(
        <>
      <h6>hello i am {name} i done {course} </h6>
        </>
        )

    
    
}

export default Childcomponent;












// // by useing simple context  for only 1 data without context hook  ( working ) -------------
// function Childcomponent() {
    
//     return(
//         <>
//       <data.Consumer>
//       {
//           (name) => {
//               return(
//               <>
//               <p>context api </p>
//               <h6>i am  {name} </h6>
//               </>
//               );
//           }
//       }
//       </data.Consumer >
//         </>
//         )

    
    
// }


/////////////////////////////////////////////////////



//  by useing simple context  for 2 data---- ( not workeing )-------
// function Childcomponent() {
//     const name = useContext(data);
//     const course = useContext(data2); 
    
//   return(
       
//       <>
//       <data.Consumer>
//       {
//           (name) => {
//               return(
//                         <data2.Consumer>
//                           {
//                               (course) => {
//                               return(
//                               <h6>i am {name} and i done {course} </h6>
//                               )
//                               }
//                           }  
//                           <data2.Consumer>
//                      )
//                 }
//       }
//       </data.Consumer>
     
//         </>
        
//       );
//  }
