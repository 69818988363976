import Fcontent from './Fcontent';

function Footer() {

    return (
        <footer style={{marginTop:"15%" , position:"absolute"}} className="footer--pin">
        <>
       
        <dic class = "container">
            <div class  ="row"  style={{backgroundColor:"lightgrey"}}>
                <div class  ="col-lg-8">
                    <h5 style={{textAlign:"center"}}><i>This is a sample  Footer component </i></h5>
                    <center><Fcontent /></center>
                    
                </div>
                <div class ="col-lg-4">
                    <h5>This is Footer qkwbq wqfb qwf bqu wf bu q yb nergn  nerg e qiufniq qiuhq3piu q uiu4 quf oiu</h5>
                    <u>
                        <li><a href="https://www.w3schools.com">W3School</a></li>
                        <li><a href="https://www.google.com">Google</a></li>
                        <li><a href="https://www.twitter.com">Twitter</a></li>
                    </u>
                    
                </div>
                
            </div>
        </dic>
        
        </>
        </footer>
        );
        }

        export default Footer;