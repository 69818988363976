import React from 'react';
import ReactDOM from 'react-dom/client';
//import Navbar from '.src/Navigation/Nav.js';


import './index.css';
//import Form from './Form';
//import CityName from './Colors';
import Newnav from './Newnav';
import App from './App';
import Footer from './footer';
//import Search from './Api';
import Weather from './Weather';
import Usecontext from './Usecontext';
import Story from './Apistory';
import Phpapi from './Phpapi' ;
import New from './New' ;
import Counter from './Reducer';
import Memo from './Memo';
import Conditional from './Conditional';
import Post from './Postapi';
import Update from './Update';
import Delete from './Delete';
import Ref from './Useref';
import Parent from './Parentcomponent';
import Newmemo from './Usememo';
import Map from './Map';
import Register from  './Register';
import Calculate from './Calculate';
import Quiz from './Quiz';
//import Bootstrap from './Bootstrap';
//import Gallery from './Profile';
//import LoginForm from './Auth1';
//import Apilogin from './Apilogin';
//import Login from './Auth1'
//import Send from './Newpost';
//import Weatherasync from './Async';  <Weatherasync />
//import Temperature from './Temperature' ;
//import Nav from './Selfcomponent'; 
//import Navbar from './Navbar';



import reportWebVitals from './reportWebVitals';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  
    <App /><br></br><br></br>
  <Quiz /><br></br><br></br>

  </React.StrictMode>

);



<script crossorigin src="..."></script>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//<Nav />
reportWebVitals();




// ALL USEING COMPONENTS ARE HERE -----------------

//   <Usecontext /><br></br><br></br>
//     <Weather /><br></br><br></br>
//     <Phpapi />
//     <New name ="its a props example "  />
//     <Story />
//     <Counter />
//     <Memo />
//     <Conditional /> <br></br><br></br>
   
//     <Post /><br></br>
//     <Update /><br></br>
//     <Delete /><br></br>
//     <Ref /><br></br>
//     <Parent /><br></br>
//     <Newmemo />
//       <Register />
//     <Map />
//     <Calculate /><br></br><br></br>
//      <Gallery />
//      <Apilogin />
//    <Footer/>


// single component  with different img ------
//   <Note  imgsrc=" https://i.imgur.com/MK3eW3As.jpg"
//   title ="1st image" />
  
//     <Note imgsrc = "https://i.imgur.com/yXOvdOSs.jpg"
//     title = "2nd image "  />
    
//       <Note imgsrc ="https://i.imgur.com/QIrZWGIs.jpg"
//      title="3rd image"   />
