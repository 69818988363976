import React, { useState } from "react";
import ReactDOM from "react-dom/client";


function CityName() {
  const [city, setCity] = useState("hyderabad");

  return (

  <>
  <h5> select your city</h5>
   <h5>city name  is {city}!</h5>
      <button
        type="button"
        onClick={() => setCity("pune")}
      >pune</button>

      <button type ="button" 
      onClick ={() => setCity("banglore")}>banglore</button>

      <button type="button" onClick={() =>setCity("delhi")}>delhi</button>
  </>


  );


}
export default CityName;