import React, { useReducer } from 'react';

const initialsate =0;
const reducer = (state,action) =>{
     switch(action){
        case "increment":
            return state+1
        case"decrement":
            return state-1
        default:
            return state
     }
}



function  Counter() {
    const[count,dispatch] = useReducer(reducer,initialsate) 
    return(
        <>
        <div>count ={count}</div>
        <button onClick={ ()=>dispatch("increment")}>increment</button><br></br>
        <button onClick={ ()=>dispatch("decrement")}>decrement</button>
        </>
    )
}

export default Counter;