//------------------------
import React ,{useState} from 'react';
import axios from 'axios';

function Post() {

const [post, setPost] = useState({
    name:'',
})
const handleInput = (event) =>{
    setPost({[event.target.name]: event.target.value })
}

function handleSubmit(event){
    event.preventDefault()
    //console.log(post);
    const postData = post;
fetch('https://testing.bwjts.com/udhit/post.php', {
  method: 'POST',
  crossorigin: true,    
    mode: 'no-cors', 
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(postData)
})
  .then(response => response.json())
  .then(data => console.log(data))
  .catch(error => console.error("error getting:".error));
}
  
        return (
            <>
            <div className="wrapper">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Add User Name</label>
                        <input type="text" name="name" onChange={handleInput} className="form-control" />
                    </div>
                    <div className="form-group">
                        <input type="submit" value="Create User" className="btn btn-success btn-block" />
                    </div>
                </form>
            </div>
            </>
        );
    
}

export default Post;






// import React, {useState} from 'react';
// import axios from 'axios';

//     function Post() {
//         const [name, setName] = useState("");
        
//         const handleClick = () => {
//                 axios.post('http://localhost/API/post.php'.q, {
//                         params: {
//                             q: name
//                         }
//                         })
//                 .then((response)=>{
//                 console.log(response.data);
//                 //console.log('name');

//                 })
//             }
    

//     return(
        
//         <>
//         <div class ="container">
//             <h5> search weather </h5>
//             <input type ="text" placeholder ="name" name ="name" value={name} onChange={(e)=>{setName(e.target.value);}}/>
            
//             <button type="submit"  onClick={handleClick} > Clic here </button>


//         </div>

//         {
//         /* <ul>
//         {
//         city.map((item ,key) =>
//         <tr key={key}>
//           <td> {item.cloud} </td>
//           <td> {item.coord} </td>
//           </tr>
//         )}
//       </ul> */}
//         </>
        
//     );
// }

// export default Post;




// 2
// import React from 'react';
// import axios from 'axios';
// import { name , city } from './Module.js';

// export default class Post extends React.Component {
//   state = {
//     name: ''
//   }

//   handleChange = event => {
//     this.setState({ name: event.target.value });
//   }

//   handleSubmit = event => {
//     event.preventDefault();

//     const user = {
//       name: this.state.name
//     };
// //console.log(user);
//     axios.post("API/post.php ", { user })
//       .then(res => {
//         console.log(res);
//         console.log(res.data);
//       })
//   }

//   render() {
//     return (
//         <>
//       <div>
//         <name />
//         <city />
//         <form onSubmit={this.handleSubmit}>
//           <label>
//             Person Name:
//             <input type="text" name="name" onChange={this.handleChange} />
//           </label>
//           <button type="submit">Add</button>
//         </form>
//       </div>
//       </>
//     )
//   }
// }



// 3
// import React, { useState } from 'react';
// import axios from 'axios';

// function Post() {
//   const [formData, setFormData] = useState({
//     name: 'demo',

//   });

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       const response = await axios.post('http://localhost/API/post.php', formData);

//       console.log(response.data); // handle the response data
//     } catch (error) {
//       console.error(error); // handle any errors that might occur
//     }
//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <input type="text" name="name" value={formData.name} onChange={handleInputChange} placeholder="Name" />
  
//       <button type="submit">Send</button>
//     </form>
//   );
// }

// export default Post;




// 4
// import React, { useState } from 'react'
// import axios from 'axios'
//  function Post() {
//   const [userName, createUser] = useState('')
//   const onSubmit = async (e) => {
//     e.preventDefault()
//     const post = { userName: userName }
//     try {
//       const res = await axios.post('http://localhost/API/post.php', post)
//       console.log(res.data)
//     } catch (e) {
//       alert(e)
//     }
//   }
//   return (
//     <div className="container mt-2">
//       <h2>React HTTP Post Request Example</h2>
//       <form onSubmit={onSubmit}>
//         <div className="mb-2 mt-3">
//           <input
//             type="text"
//             placeholder="Name"
//             className="form-control"
//             onChange={(event) => {
//               createUser(event.target.value)
//             }}
//             value={userName}
//           />
//         </div>
//         <button type="submit" className="btn btn-danger">
//           Create
//         </button>
//       </form>
//     </div>
//   )
// }

// export default Post;
 
 
 
// 5 
//  import React, {useState} from 'react';
// import axios from 'axios';

//     function Post() {
//        // const [city, setCity] = useState("");
//         const [name, setName] = useState("");
        
//         const handleClick = () => {
//                 axios.post('http://localhost/API/post.php?bittu=sharma', {
//                         params: {
//                             p: name
                            
//                         }
//                         })
//                 .then((response)=>{
//                 console.log(response.data);
//                 //console.log('name');

//                 })
//             }
    

//     return(
        
//         <>
//         <div class ="container">
//             <h5>  </h5>
//             <input type ="text" placeholder ="name" name ="name" value={name} onChange={(e)=>{setName(e.target.value);}}/><br></br>

//             <button type="submit"  onClick={handleClick} > Clic here </button>


//         </div>

//         </>
//     );


//  }

//  export default Post;




// import React, { Component } from 'react';
// import axios from 'axios';
// export default class Post extends Component {
//     constructor(props) {
//         super(props)
//         this.onChangeUserName = this.onChangeUserName.bind(this);
//         this.onSubmit = this.onSubmit.bind(this);
//         this.state = {
//             name: ''
           
//         }
//     }
//     onChangeUserName(e) {
//         this.setState({ name: e.target.value })
//     }
   
//     onSubmit(e) {
//         e.preventDefault()
//         const userObject = {
//             name: this.state.name
            
//         };
//         axios.post('http://localhost/API/post.php', userObject)
//             .then((res) => {
//                 console.log(res.data)
//             }).catch((error) => {
//                 console.log(error)
//             });
//         this.setState({ name: '',  })
//     }

//     render() {
//         return (
//             <div className="wrapper">
//                 <form onSubmit={this.onSubmit}>
//                     <div className="form-group">
//                         <label>Add User Name</label>
//                         <input type="text" value={this.state.name} onChange={this.onChangeUserName} className="form-control" />
//                     </div>
                   
//                     <div className="form-group">
//                         <input type="submit" value="Create User" className="btn btn-success btn-block" />
//                     </div>
//                 </form>
//             </div>
//         )
//     }
// }