import React, { useState } from 'react';

 

function Newmemo() {
    
      const [Addition , setAddition] = useState(0);
    const [Minus , setMinus] = useState(100)
    
    
    return(
        
        <>
        <button  onClick ={ () => setAddition(Addition + 1) }> Addition</button>{Addition}<br></br>
        <button onClick ={ () => setMinus(Minus - 1) }> substract</button>{Minus}
        </>
        
        
        )
    
    
}

export default Newmemo;