
import Blog from "./Blog";
import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
function ContactForm() {
  const [state, handleSubmit] = useForm("mvonqyqe");
  if (state.succeeded) {
      return <p>Thanks for messing us!....we will contact you soon...</p>;
  }
  return (
    <>
    <Blog /><br></br><br></br>
      <form onSubmit={handleSubmit}>

        <div  class="container" >
        <div class="row" style={{textAlign:"center" }}>
         <div class="col-lg-12">

      <label htmlFor="email">
        Email Address
      </label>
      <input
        id="email"
        type="email" 
        name="email"
      /><br></br>
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      </div>
      </div>

      <div class="row" style={{textAlign:"center"}}>
        <div class="col-lg-12">
          <label htmlfor="message">
            your message
            </label>
      <textarea
        id="message"
        name="message"
      /><br></br>
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      </div>
      </div>
      <center><button  type="submit" disabled={state.submitting} >
        Submit
      </button ></center>
      </div>
    </form>
    </>
  );
}

export default ContactForm;




