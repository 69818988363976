import React,{ createContext } from 'react';
import Childcomponent from './Childcomponent';


const data = createContext();
const data2 = createContext();


function Parent() {
const name = "A udit "
const course ="MCA"


return(
    <>
    <data.Provider value={name} >
      <data2.Provider value={course} >
        <Childcomponent />
      </data2.Provider>
    </data.Provider >
    </>
    );
    
}

export default Parent ;
export {data, data2} ;
