import React, {useState} from 'react';
import axios from 'axios';

    function Weather() {
        const [city, setCity] = useState("");
        
        const handleClick = () => {
                axios.get('https://api.openweathermap.org/data/2.5/weather', {
                        params: {
                            appid: 'afeaecaa5edde368fd0ccb125e349e67',
                            q: city
                        }
                        })
                .then((response)=>{
                console.log(response.data);
                //console.log('name');

                })
            }
    

    return(
        
        <>
        <div class ="container">
            <h5> search weather </h5>
            <input type ="text" placeholder ="search city" name ="city" value={city} onChange={(e)=>{setCity(e.target.value);}}/>
            
            <button type="submit"  onClick={handleClick} > Clic here </button>


        </div>

        {
        /* <ul>
        {
        city.map((item ,key) =>
        <tr key={key}>
          <td> {item.cloud} </td>
          <td> {item.coord} </td>
          </tr>
        )}
      </ul> */}
        </>
        
    );
}

export default Weather;